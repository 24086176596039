import React from "react";
import { Col } from "react-bootstrap";
import "./pager.css";

const Pager = (props) => (
  props.total === 1 ? null :
    <Col className="pager_container">
      {[...Array(props.total).keys()].map((element, index) => (
        <span
          key={index}
          className="pager_item"
          onClick={() => props.onPageChange(element)}
        >
          {element + 1}
        </span>
      ))}
    </Col>
);

export default Pager;
