import React from 'react';
import { Container, Navbar, Row, Col } from 'react-bootstrap';
import { Link, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import './header.css';

const Header = (props) => {
    const { t, i18n } = useTranslation();
    let match = useMatch("/");
    return (match ? <></> : <Container fluid className="px-0">
        <Row>
            <Col col={12} className="text-center"><img className="logo" src="/assets/common/logo.png" alt="" /></Col>
        </Row>
        <Row>
            <Col xs={12} md={12} xl={12}>
                <Navbar expand="md" className="sticky-top color animate__animated animate__backInDown">
                    <div className="d-md-none col-10 float-left">
                        <span className="lang-item from-left" onClick={() => i18n.changeLanguage("it")}>Ita</span>
                        <span className="lang-item mx-2 seperator">|</span>
                        <span className="lang-item from-left" onClick={() => i18n.changeLanguage("en")}>Eng</span>
                        <span className="lang-item mx-2 seperator">|</span>
                        <span className="lang-item from-left" onClick={() => i18n.changeLanguage("rus")}>Rus</span>
                    </div>
                    <Navbar.Toggle className="col-2 float-right"></Navbar.Toggle>
                    <Navbar.Collapse className="row" >
                        <div className='col d-none d-md-block col-md-4 col-xl-3'>
                            <span className="lang-item from-left" onClick={() => i18n.changeLanguage("it")}>Ita</span>
                            <span className="lang-item seperator">|</span>
                            <span className="lang-item from-left" onClick={() => i18n.changeLanguage("en")}>Eng</span>
                            <span className="lang-item seperator">|</span>
                            <span className="lang-item from-left" onClick={() => i18n.changeLanguage("rus")}>Rus</span>
                        </div>
                        <Col className="col-md-7 col-lg-5 col-xl-6 text-lg-center">
                            <Link to="home" className="menu-item from-left nav-link">{t('header.home')}</Link>
                            <Link to="campaign" className="menu-item from-left nav-link">{t('header.campaign')}</Link>
                            <Link to="contact" className="menu-item from-left nav-link">{t('header.contact')}</Link>
                            <Link to="e-store" className="menu-item from-left nav-link">{t('header.estore')}</Link>
                            <a target={'_blank'} className="b2b-link d-md-none d-block" href="https://b2b.gratude.com/login" rel="noreferrer">{t('header.b2b')}</a>
                        </Col>
                        <Col className="col-md-1 col-xl-1 d-none d-md-block position-absolute end-0">
                            <a className='b2b-link text-end align-middle' rel='noreferrer' target={'_blank'} href="https://b2b.gratude.com/login">{t('header.b2b')}</a>
                        </Col>
                    </Navbar.Collapse>
                </Navbar>
            </Col>
        </Row >
    </Container >)
}
export default Header;