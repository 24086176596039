import React from 'react';
import { useNavigate } from 'react-router-dom';
import './landing.css';
const Landing = (props)=>{
    let navigate = useNavigate();
    setTimeout(() => {
        navigate('home');
    },3000);
   return( 
   <div className="backgroundImage"></div>)
}
   

export default Landing;