import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../../pages/home';
import Campaign from '../../pages/campaign';
import Contact from '../../pages/contact';
import EStore from '../../pages/e-store';
import Footer from '../common/footer';
import Header from '../common/header';
import '../../i18n';
import ScrollUp from 'react-scroll-up';

import Landing from '../../pages/landing';

const Root = () =>
    <Suspense fallback={null}>
        <BrowserRouter>
            <Header></Header>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="home" element={<Home />} />
                <Route path="campaign" element={<Campaign />} />
                <Route path="contact" element={<Contact />} />
                <Route path="e-store/*" element={<EStore />}/>
            </Routes>
            <Footer></Footer>
        </BrowserRouter>
        <ScrollUp showUnder={160} >
            <svg width="4em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
            </svg>
        </ScrollUp>
    </Suspense>

export default Root;