import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

const EStoreCategoryItem = (props) => {
    return <Col xs={6} sm={6} lg={3} md={4}>
        <Link className="estore_a" to={`${props.title.toLowerCase()}`} >
            <div className="div_pad col-md-12 col-lg-12 col-sm-12 col-xs-12"><img loading='lazy' className="thumbnail" src={`${props.src}`} alt={props.title}></img></div>
            <div className="estore_title col-md-12 col-lg-12 col-sm-12 col-xs-12" >{props.title}</div>
        </Link>
    </Col>
}
export default EStoreCategoryItem;