import "./e-store.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Container } from "react-bootstrap";
import { Route, Routes, useMatch } from "react-router-dom";
import Pager from "../../components/common/pager";
import ProductList from "./components/product-list";
import Divider from "../../components/common/divider";
import ProductDetail from "./components/product-detail";
import EStoreCategoryItem from "./components/category-item";
const PageCount = 12;
const EStore = (props) => {
  const [store, setStore] = useState({ page: 0, data: [] });
  const match = useMatch("e-store");
  const onPageChange = (currentPage) =>
    setStore((prevState) => ({ page: currentPage, data: prevState.data }));
  useEffect(() => {
    axios.get("/data/data.json").then((result) => {
      setStore((prevState) => ({ page: prevState.page, data: result.data }));
    });
  }, []);
  return (
    <>
      <Divider></Divider>
      {match && store.data.length > 0 ? (
        <>
          <Container>
            <Row key={store.data.length}>
              {store.data
                .slice(store.page * PageCount, (store.page + 1) * PageCount)
                .map((obj, index) => (
                  <EStoreCategoryItem
                    key={index}
                    title={obj.title}
                    src={obj.src}
                  />
                ))}
            </Row>
          </Container>
          <Pager
            total={store.data.length % PageCount === 0 ? store.data.length / PageCount : parseInt(store.data.length / PageCount + 1)}
            onPageChange={onPageChange}
          ></Pager>
        </>
      ) : null}

      <Routes>
        {store.data && store.data.length > 0
          ? store.data.map((category, catIndex) =>
            <Route
              key={`${category.title}-${catIndex}`}
              path={`${category.title.toLowerCase()}`}
              element={<ProductList item={category} />}
            />)
          : null}
        {store.data && store.data.length > 0
          ? store.data.map((category) =>
            category.data.map((product, prodIndex) =>
              <Route
                key={`${product.code}-${prodIndex}`}
                path={`${category.title.toLowerCase()}/${product.code}`}
                element={<ProductDetail item={product} category={category} path={category.root_src} />}
              />))
          : null}
      </Routes>
    </>
  );
};
export default EStore;
