import React from 'react';
import './social.css';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
const Social = () =>{
    const { t } = useTranslation();
    let match = useMatch("/home");
    return(match?<></>:<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
    <div className="socail_footer">
        <div className="col-md-12-col-lg-12 col-xs-12 text-center">{t('footer.followus')}</div>
        <a href="https://www.instagram.com/gratude/" target="_blank" rel="noopener noreferrer"><img src="/assets/common/ins_1.jpg" className="socail_icon_footer"  alt=""/></a>
        <a href="https://www.facebook.com/gratudeofficial" target="_blank" rel="noopener noreferrer"><img src="/assets/common/face_1.jpg" className="socail_icon_footer" alt=""/></a>
        <a href="https://www.youtube.com/channel/UCEoDEF-qxdk9g0kNJQF3Q9g" target="_blank" rel="noopener noreferrer"><img src="/assets/common/youtube_1.jpg" className="socail_icon_footer" alt=""/></a>
    </div>
</div>)
}

       

export default Social;