import "./campaign.css";
import React from "react";
import Divider from "../../components/common/divider";
import { Row, Container, Modal } from "react-bootstrap";
import CampaignItem from "./components/campaign-item";
import Pager from "../../components/common/pager";

export default class Campaign extends React.Component {
  state = {
    isModalOpen: false,
    page: 0,
    selectedVideo: 0,
    data: [
      { assetUrl: "/assets/campaign/1.jpg", videoUrl: "f0pr5cHaBOw" },
      { assetUrl: "/assets/campaign/2.jpg", videoUrl: "J41vKMIIJs0" },
      { assetUrl: "/assets/campaign/3.jpg", videoUrl: "Bng2gfc_0bU" },
      { assetUrl: "/assets/campaign/4.jpg", videoUrl: "RjceF69DJQM" },
      { assetUrl: "/assets/campaign/5.jpg", videoUrl: "s66hIM-LLkY" },
      { assetUrl: "/assets/campaign/6.jpg", videoUrl: "fYpxwSjgH1M" },
      { assetUrl: "/assets/campaign/7.jpg", videoUrl: "wI4iX-OpmsQ" },
      { assetUrl: "/assets/campaign/8.jpg", videoUrl: "lEy1Quo-Ek0" },
      { assetUrl: "/assets/campaign/9.jpg", videoUrl: "X1aBgscq35I" },
      { assetUrl: "/assets/campaign/10.jpg", videoUrl: "9owRx8ZL_-c" },
      { assetUrl: "/assets/campaign/11.jpg", videoUrl: "tGWe-jFi8y4" },
      { assetUrl: "/assets/campaign/12.jpg", videoUrl: "1bUd63yZn9E" },
      { assetUrl: "/assets/campaign/13.jpg", videoUrl: "43OQn01jCfk" },
      { assetUrl: "/assets/campaign/14.jpg", videoUrl: "e4jRWanh41s" },
      { assetUrl: "/assets/campaign/15.jpg", videoUrl: "l2q1f0G0JtI" },
      // { assetUrl: "/assets/campaign/16.tif", videoUrl: "qWmNriG34C8" },
      // { assetUrl: "/assets/campaign/17.tif", videoUrl: "l-IOWGk7xtE" },
      // { assetUrl: "/assets/campaign/18.tif", videoUrl: "y_Mzdo6vqR8 " },
      // { assetUrl: "/assets/campaign/19.tif", videoUrl: "zcHWqpE5AI0" },
      // { assetUrl: "/assets/campaign/20.tif", videoUrl: "xh3IHk7tqmI" },
    ],
  };
  onItemClicked = (itemIndex) =>
    this.setState({ selectedVideo: itemIndex, isModalOpen: true });
  onPageChange = (page) => {
    this.setState({ page: page, selectedVideo: 0 });
  };
  render() {
    let components = (
      <Row key={this.state.data.length}>
        {this.state.data
          .slice(this.state.page * 6, (this.state.page + 1) * 6)
          .map((dataItem, index) => (
            <CampaignItem
              key={index}
              url={dataItem.assetUrl}
              index={index}
              onItemClick={this.onItemClicked}
            />
          ))}
      </Row>
    );
    return (
      <>
        <Divider></Divider>
        <Container>{components}</Container>
        <Modal
          show={this.state.isModalOpen}
          onHide={() => this.setState({ isModalOpen: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <iframe
              title="Video"
              width="100%"
              height="600px"
              src={`https://www.youtube.com/embed/${
                this.state.data[this.state.selectedVideo + this.state.page * 6]
                  .videoUrl
              }`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
        <Pager
          total={
            this.state.data.length % 6 === 0
              ? this.state.data.length / 6
              : parseInt(this.state.data.length / 6 + 1)
          }
          onPageChange={this.onPageChange}
        ></Pager>
      </>
    );
  }
}
