import React, { useState } from 'react';
import Divider from '../../components/common/divider';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import './contact.css';
import { useTranslation } from 'react-i18next';
const Contact = () => {
    const { t } = useTranslation();
    const [formModel, setFormModel] = useState({
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        country: "",
        region: "",
        message: "",
        isValid:false
    });

    const [isMailSent, setIsMailSent] = useState(false);
    const handleInputChange = event => {
        event.persist();
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormModel(preState => ({ ...preState, [event.target.name]: value }));
    }
    const submitForm = event => {
        let fullname = `${formModel.firstName} ${formModel.lastName}`;
        formModel['fullName'] = fullname;
        console.log(formModel);
        
        axios.post('http://mailapi.aerotivelabs.com/contact', { ...formModel }, { headers: { 'Content-Type': 'application/json' } }).then(res => {
            setIsMailSent(true);
        }).catch(e => {

        })

    }
    return (<>
        <Divider></Divider>
        <Container>
            <Col className="contact_inline align-top" md={4}>
                <Row><p className="contact_p">{t('contact.mottoline1')}</p></Row>
                <Row><p className="contact_p">{t('contact.mottoline2')}</p></Row>
                <Row><p className="contact_p">{t('contact.mottoline3')}</p></Row>
            </Col>
            <Col className="contact_inline contact_text_up" md={6}>
                {isMailSent ? <div>{t('contact.sent.message')}</div>:
                    <>
                        <Row>
                            <Col className="contact_inline contact_text_up_1" md={6}>
                                <p>{t('contact.pleasefill')}</p>
                            </Col>
                            <Col className="contact_inline" md={6}>
                                <p>{t('contact.required')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="text" name="firstName" placeholder={t('contact.form.firstname')} onChange={handleInputChange}></input>
                            </Col>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="text" name="lastName" placeholder={t('contact.form.lastname')} onChange={handleInputChange}></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="email" name="email" placeholder={t('contact.form.email')} onChange={handleInputChange}></input>
                            </Col>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="telephone" name="telephone" placeholder={t('contact.form.phone')} onChange={handleInputChange}></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="text" name="country" placeholder={t('contact.form.country')} onChange={handleInputChange}></input>
                            </Col>
                            <Col className="form-group contact_inline" md={6}>
                                <input className="form-control textarea-addon" type="text" name="region" placeholder={t('contact.form.region')} onChange={handleInputChange}></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={12}>
                                <textarea className="form-control textarea-addon contact_msg" name="message" placeholder={t('contact.form.message')} onChange={handleInputChange}></textarea>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={1}>
                                <input type="checkbox" className="form-check-input contact_chck" name="isValid" onChange={handleInputChange}></input>
                            </Col>
                            <Col className="form-group contact_inline" md={11}>
                                <label className="form-check-label contact_label">{t('contact.form.notification')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group contact_inline" md={10}>

                            </Col>
                            <Col className="form-group contact_inline" md={2}>
                                <button disabled={!formModel.isValid} onClick={submitForm} type="button" className="btn btn-primary">Send</button>
                            </Col>
                        </Row>
                        <Row>
                        <Col className="form-group contact_inline" md={10}>

                        </Col>
                       </Row></>
            }
            </Col>

        </Container>
    </>)
}
export default Contact;