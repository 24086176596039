import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { NavLink, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './footer.css';
const Footer = () => {
    const { t } = useTranslation();
    let match = useMatch("/");
    return (match?<></>:<Container fluid className="footer">
        <div className="footer-menu">
                <NavLink to="home" className="footer-line from-left">{t('header.home')}</NavLink>
                <NavLink to="campaign" className="footer-line from-left">{t('header.campaign')}</NavLink>
                <NavLink to="contact" className="footer-line from-left">{t('header.contact')}</NavLink>
                <NavLink to="e-store" className="footer-line from-left">{t('header.estore')}</NavLink>
        </div>
        <Container fluid>
            <Row>
                <Col className="footer-text-left">{t('footer.privacy')} © 2019 <img alt="" src="/assets/common/logo-small.png" /></Col>
                <Col className="footer-text-right">{t('footer.allrightsreserved')} <img alt="" src="/assets/common/logo-small.png" /></Col>
            </Row>
            <Row><Col className="footer-text-left text-left">{t('footer.warning')}</Col></Row>
        </Container>
    </Container>)
}
export default Footer;