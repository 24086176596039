import React from "react";
import "./collection.css";
import TrackVisibility from "react-on-screen";
import { Row, Col } from "react-bootstrap";
import Divider from "../../../../components/common/divider";
import { useTranslation } from "react-i18next";

const Collection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="px-5 mx-5">
        <Col xs={12} md={12}>
          <TrackVisibility once offset={300}>
            {({ isVisible }) => (
              <Row className="justify-content-center">
                <Col sm={10} md={10} lg={8} xl={6} className="pt-xl-5">
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-sm-center"
                        : "invisible"
                    }
                  >
                    <div className="collection_p collection_img_width">
                      <p>{t("home.mottoline1")}</p>
                      <p>{t("home.mottoline2")}</p>
                      <p>{t("home.mottoline3")}</p>
                    </div>
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col xl={5} sm={10} md={10} lg={8}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/home/collection/G1.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G2.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-xs-center text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G3.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G4.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G5.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G6.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G7.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G8.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G9.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G10.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G11.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G12.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G13.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G14.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G15.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G16.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G17.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G18.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G19.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G20.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G21.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G22.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G23.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={12}>
          <TrackVisibility once offset={220}>
            {({ isVisible }) => (
              <Row className="justify-content-center mt-3">
                <Col sm={10} md={10} lg={8} xl={6}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInLeft align-middle text-xs-center text-sm-center text-md-center text-xl-left"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid "
                      alt=""
                      src="assets/home/collection/G24.jpg"
                    />
                  </div>
                </Col>
                <Col xl="auto"></Col>
                <Col sm={10} md={10} lg={8} xl={5}>
                  <div
                    className={
                      isVisible
                        ? "visible animate__animated animate__bounceInRight align-middle float-xl-right float-sm-none text-sm-center"
                        : "invisible"
                    }
                  >
                    <img
                      className="img_height img-fluid"
                      alt=""
                      src="assets/home/collection/G25.jpg"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </Col>
      </Row>
      <div className="collection_text">
        <strong className="collection_strong ">{t("home.store")}</strong>
      </div>
      <Divider></Divider>
    </>
  );
};
export default Collection;
