import React from 'react';
import './home.css';
import Slider from './components/slider'
import Divider from '../../components/common/divider';
import Collection from './components/collection';
import Shop from './components/shop';
import Social from '../../components/common/social';

const Home = () => <>
    <Slider></Slider>
    <Divider></Divider>
    <Collection></Collection>
    <Shop></Shop>
    <Social></Social>
</>
export default Home;